@import "colors";

@font-face {
	font-family: BuenosAires;
	src: url(https://pupford-fonts.b-cdn.net/BuenosAiresVariableUpright.ttf);
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: BuenosAires,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $textColor;
}

body.modal-open {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

label {
  font-size: 14px;
  margin-bottom: 4px;
  display: block;
  font-weight: 600;
}

textarea,
input[type=email],
input[type=text],
input[type=number],
input[type=password],
input[type=date] {
  border: 2px solid $secondaryColorDark;
  background-color: $transparent;
  border-radius: 8px;
  position: relative;
  color: $textColor;
  box-shadow: none;
  padding: 10px 12px;
  font-size: 16px;
  width: 100%;
	line-height: 1;
	max-width: 100%;
  font-family: BuenosAires,sans-serif;
  margin-bottom: 15px;

  &.error {
    border-color: $primaryColor;
  }
}

input[type=email]:focus,
input[type=text]:focus,
input[type=number]:focus,
input[type=password]:focus {
  outline-width: 0;
  border-color: #61bdf7;
}

button,
.button {
  background-color: $primaryColor;
  color: $lightTextColor;
  border-radius: 25px;
  padding: 0;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  &.secondary {
    background-color: $secondaryColorDark;
  }

  &.success {
    background-color: $successColor;
  }

  &.alternate {
    background-color: $alternateColor;
  }

  &.small {
    font-size: 14px;
    padding: 6px 12px;
  }
}

button:hover,
.button:hover {
  opacity: 0.8;
}

button.alternate:hover,
.button.alternate:hover {
  background-color: $alternateColor;
  opacity: 0.8;
}

button.success:hover,
.button.success:hover {
  background-color: $successColor;
  opacity: 0.8;
}

button:focus,
.button:focus {
  outline: 0;
}

button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

select {
	background: linear-gradient(45deg, transparent 50%, #000 50%), linear-gradient(135deg, #000 50%, transparent 50%), linear-gradient(to right, #fff, #fff);
	background-position: calc(100% - 21px) calc(1em + 5px), calc(100% - 16px) calc(1em + 5px), 100% 0;
	background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
	background-repeat: no-repeat;
	line-height: 1.4;
	background-color: #ffffff;
	border-radius: 4px;
	position: relative;
	color: #404040;
	box-shadow: none;
	border: 1px solid #bfbfbf;
	margin-bottom: 15px;
	padding: 10px 12px 10px;
	width: 100%;
	font-size: 16px;
	margin: 0;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
	line-height: 1.65;
}

select:focus {
  outline: none;
}

.well {
  background: #FFFFFF;
  border: 1px solid $borderDark;
  border-radius: 6px;
  padding: 20px 25px;

  &.well-secondary {
    border-color: rgba(255, 51, 18, 0.2);
  }

  &.well-light {
    border-color: $borderLight;
  }

  &.well-large {
    border-radius: 20px;
  }
}

.behavior-modal {
  text-align: center;

  .behavior-modal-title {
    font-weight: bold;
    font-size: 18px;
  }

  .behavior-modal-text {
    font-size: 16px;
  }

  .behavior-modal-rating {
    margin: 20px 0;
  }
}
