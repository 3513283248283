$primaryColor: #E64B38;
$primaryColorLight: #FFB4AB;
$primaryColorMedium: #FFB4AB;
$secondaryColor: #FFC600;
$secondaryColorDark: #592E2C;
$successColor: #2BB673;
$alternateColor: #243746;
$borderLight: rgba(0, 0, 0, 0.1);
$borderDark: rgba(0, 0, 0, 0.2);
$transparent: rgba(0, 0, 0, 0);
$linkColor: #0e70be;
$backgroundColor: #FAF7E8;
$textColor: #592E2C;
$lightTextColor: #FAF7E8;
