@import "colors";

.App {
  position: relative;
  padding: 10px;

  .success-container {
    text-align: center;
  }

  .error-message-container {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 0.25rem;
    padding: 0.75rem 1.25rem;
    margin-top: 15px;
    font-size: 14px;
  }

  table {
    margin-top: 10px;
    line-height: 1.4;
    background-color: $backgroundColor;
    border-radius: 4px;
    position: relative;
    color: $textColor;
    box-shadow: none;
    border: 1px solid $textColor;
    font-size: 16px;
    width: 100%;
    border-collapse: collapse;

    tr {
      border-bottom: 1px solid $textColor;

      td, th {
        text-align: left;
        padding: 10px 12px 10px;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        img {
          height: 19px;
          vertical-align: text-bottom;
          margin-right: 7px;
        }
      }

      td:last-of-type {
        text-align: right;
        width: 50px;

        a {
          background-color: rgba(0, 0, 0, 0.05);
          width: 45px;
          margin-top: 0;
          cursor: pointer;
          border-radius: 4px;
          padding: 7px 10px;

          img {
            height: 18px;
            vertical-align: baseline;
            vertical-align: text-top;
            margin-right: 0;
          }

          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }

  .submit-button {
    width: 100%;
    margin-top: 15px;
    font-size: 16px;
    padding: 10px 12px 10px;
  }
}
